/* eslint-disable max-lines */
import { TranslationsObject } from '@Contexts/I18nContext/I18nContext.types'

export const ptBR: TranslationsObject = {
  zoneSelectionPage: {
    modalTitle: 'Olá {{name}}, bem-vindo!',
    modalSubTitle: 'Selecione um país para continuar.'
  },
  contentListPage: {
    contentListPageTitle: 'Lista de conteúdo',
    customerPageTitle: 'Lista de conteúdo do cliente BEES',
    partnerstoreBreadcrumbText: 'Lista de conteúdo da fachada de loja do parceiro BEES',
    addContentButtonText: 'Criar novo conteúdo',
    searchInputPlaceholder: 'Pesquisar por título',
    filtersButtonText: 'Filtrar',
    filteredByText: 'Filtrado por',
    statusTabText: 'Status',
    editedByTabText: 'Editado por',
    editedByInputPlaceholder: 'Insira o e-mail',
    startDateInputPlaceholder: 'Insira uma data de início',
    endDateInputPlaceholder: 'Insira uma data de término',
    filtersApplyButtonText: 'Aplicar',
    contentTypeActiveFilterText: 'tipo',
    statusActiveFilterText: 'status',
    editedByActiveFilterText: 'editado por',
    startDateActiveFilterText: 'Data de início',
    startDateErrorText: 'A data deve ser pelo menos 01/01/2019',
    endDateActiveFilterText: 'Data de término',
    endDateErrorText: 'Data inválida',
    contentTitleTableHeadText: 'Título do conteúdo e tipo',
    contentTitleTableHeadTextMinified: 'Título do conteúdo',
    contentTitleTableHeadTooltipText: 'Título e tipo de lançamento.',
    actionsTableHeadText: 'Ações',
    audienceTableHeadText: 'Público',
    launchTableHeadText: 'Data e hora de lançamento',
    launchTableHeadTooltipText: 'Data e hora de lançamento.',
    timezoneTableHeadTooltipText: 'Fuso horário do lançamento.',
    statusTableHeadTooltipText: 'Status de lançamento.',
    lastEditTableHeadText: 'Última edição em',
    lastEditTableHeadTooltipText: 'Data, hora e autor do e-mail.',
    deleteLaunchButtonTooltipText: 'Excluir lançamento',
    deleteLaunchModalTitle: 'Tem certeza de que deseja excluir este lançamento?',
    deleteLaunchModalWarningText:
      'Você perderá todos os dados armazenados neste lançamento depois de excluí-los',
    deleteLaunchModalWarningTextMinified: 'Todas as informações de conteúdo serão perdidas.',
    deleteLaunchModalCancelButtonTextMinified: 'Não, voltar',
    deleteLaunchModalSubmitButtonTextMinified: 'Sim, excluir',
    deleteLaunchMessageNotificationText: 'Lançamento excluído!',
    deleteLaunchNotFoundErrorNotificationText: 'Não foi possível encontrar o lançamento!',
    deleteLiveLaunchErrorNotificationText: 'Lançamento ao vivo não pode ser excluído!',
    noResultsWereFoundText: 'Nenhum resultado encontrado.',
    bannerLaunchTitle: 'Lançamento de banner',
    webViewLaunchTitle: 'Lançamento de visualização da web',
    tableItemActionShowDetailsText: 'Mostrar detalhes'
  },
  contentEditorPage: {
    contentEditorPageTitle: 'Editar lançamento',
    newLaunchPageTitle: 'Criar lançamento',
    launchInformationText: 'Propriedades de lançamento',
    contentTitleLabel: 'Título',
    contentTitlePlaceholder: 'Adicionar um título',
    contentTitleErrorText: 'Você precisa criar um título.',
    contentTypePlaceholder: 'Escolha um tipo',
    contentTypeErrorText: 'Você precisa selecionar um tipo.',
    contentTimeZonePlaceholder: 'Selecione um fuso horário',
    contentTimeZoneErrorText: 'Você precisa selecionar um fuso horário.',
    launchLabelPreviewFallbackText: 'Escolha seu banner',
    launchPlaceholder: 'Definir data',
    launchErrorText: 'Data inválida.',
    timeLabel: 'Hora de lançamento',
    timePlaceholder: 'Definir hora',
    timeErrorText: 'Hora inválida.',

    bannersText: 'Informações dos banners',
    bannerImagePlaceholder: 'Arraste e solte ou Carregue a sua imagem.',
    bannerImageButtonText: 'Carregar imagem',
    bannerImageTipText:
      'Esses banners devem ser JPG, PNG ou GIF, ter dimensões de {{widthImgText}} x {{heightImgText}} e um tamanho máximo de {{maxSizeImgText}}',
    webHomepageHeroBannerTipText:
      'Esses banners devem ser JPG, PNG ou GIF, ter dimensões de {{widthImgText}} x {{heightImgText}} ou {{widthImgText2}} x {{heightImgText2}} e um tamanho máximo de {{maxSizeImgText}}',
    bannerImageErrorText: 'Você precisa ter um banner de imagem para continuar.',
    bannerLabel: 'Título do banner',
    bannerLabelPlaceholder: 'Adicionar um rótulo',
    bannerLabelErrorText: 'Você precisa ter um rótulo de banner para continuar.',
    alternativeTextLabel: 'Texto alternativo',
    alternativeTextPlaceholder: 'Adicionar texto alternativo',
    linkLabel: 'Link',
    linkPlaceholder: 'http / https / bees / tada',
    linkErrorText: 'Esse link deve começar com http://, https://, bees:// ou tada://',
    advancedConfigurationsText: 'Configurações avançadas',
    vendorAssociationLabel: 'Associação de fornecedores',
    vendorAssociationPlaceholder: 'Insira um ou mais fornecedores',
    vendorAssociationErrorText: 'Você selecionou um fornecedor errado',
    displayToUnassociatedVendorLabel: 'Exibir para atacadistas não associados',
    addNewBannerText: 'Adicionar novo banner',
    submitButtonText: 'Enviar',
    createNewLaunchSubmitButtonText: 'Criar novo lançamento',
    updateSubmitButtonText: 'Atualizar lançamento',
    updateLaunchSuccessNotificationText: 'Lançamento atualizado!',
    createNewLaunchSuccessNotificationText: 'Lançamento criado!',
    deleteBannerNotificationText: 'Banner excluído!',
    pastDateTimeErrorNotificationText:
      'Não é possível criar um lançamento em uma data ou hora anterior.',
    updatePassedOrScheduleModuleTitle: 'Você está atualizando um lançamento existente.',
    updatePassedOrScheduleModuleDescription:
      'Após a atualização, todas as informações alteradas serão substituídas.',
    mandatoryFieldsErrorNotificationText: 'Por favor preencha os campos obrigatórios.',
    overwriteLaunchModalTitle:
      'Já existe um lançamento nesta data e hora. Tem certeza de que deseja substituir os dados do lançamento existente?',
    overwriteLaunchModalTitlePartOne: 'Já existe um lançamento nesta data e hora.',
    overwriteLaunchModalTitlePartTwo:
      'Tem certeza de que deseja substituir os dados do lançamento existente?',
    liveLaunchUpdateModalTitle: 'Você está atualizando um lançamento ao vivo.',
    liveLaunchUpdateModalText: 'Após a atualização, as alterações entrarão em vigor.',
    liveLaunchUpdateModalSubmitButtonText: 'Atualizar',
    copyLaunchModalTitle: 'Você está atualizando um lançamento {{status}}',
    copyLaunchModalText:
      'Quando você atualiza a data e a hora de um lançamento, um novo lançamento é criado automaticamente.',
    copyLaunchModalSubmitButtonText: 'Criar um novo lançamento',
    lastEditText: 'Última edição',
    requiredField: 'Campo obrigatório.',
    specificationPageTextBegin: 'Visite nossa',
    specificationPageTextLink: 'página de especificações',
    specificationPageTextEnd: 'para verificar todos os detalhes de codificação de conteúdo.',
    mediaTypeText: 'Tipo de mídia',
    imageText: 'Imagem',
    videoText: 'Vídeo',
    videoTipText: 'Os vídeos devem ter formato MP4 e menos de 20MB.',
    videoUploadButtonText: 'Carregar vídeo',
    videoAspectErrorMessage: 'O vídeo deve ter uma proporção de 16:9.',
    videoCallToActionCheckboxLabel: 'Adicionar botão call to action',
    videoCallToActionLabel: 'Rótulo de call to action',
    videoCallToActionPlaceholder: 'Adicione um rótulo para este botão',
    videoAddThumbnailLabel: 'Adicionar miniatura',
    videoAddThumbnailTooltip:
      'Se nenhuma miniatura for adicionada, uma imagem estática de metade da duração do vídeo será usada.',
    videoThumbnailTitle: 'Miniatura',
    videoThumbnailTipText:
      'As miniaturas devem ter proporção 16:9, formato JPG ou PNG e menos de 1MB.',
    videoThumbnailUploadButtonText: 'Procurar arquivos',
    videoThumbnailEnableMessage: 'Você precisa enviar o vídeo novamente para habilitar este campo.',
    videoThumbnailAspectErrorMessage: 'A miniatura deve ter uma proporção de 16:9.',
    webViewLinkTypeText: 'Link de visualização da web',
    siteUrlLabel: 'URL do site',
    siteUrlPlaceholder: 'Digite o URL de visualização da web',
    gameLinkLabel: 'Link do jogo',
    gameLinkPlaceholder: 'O URL do CMS será mostrado aqui.',
    createGameBreadcrumbText: 'Criar Jogo',
    editGameBreadcrumbText: 'Editar Jogo',
    editContentText: 'Editar conteúdo',
    copyLinkText: 'Copiar link',
    fileIsTooBigErrorMessage:
      'O arquivo é muito grande. O tamanho máximo de arquivo permitido é 2,00 MB',
    titleMinimumCharactersErrorMessage: 'O título precisa de pelo menos 4 caracteres',
    urlErrorMessage: 'Você precisa inserir um URL válido',
    audienceSegmentationTitle: 'Segmentação',
    audienceSegmentationSubtitle: 'Escolha quem deve ver este banner.',
    audienceSegmentationSubtitleUS: 'Defina quem será impactado.',
    audienceSegmentationToggleLabel: 'Exibir banner para todos os PDVs',
    audienceSegmentationToggleLabelUS: 'Exibir para atacadistas não associados',
    customAudiencesText: 'Públicos personalizados',
    addCustomAudienceButtonText: 'Adicionar público personalizado',
    pocsTargetedText: 'PDVs selecionados',
    audienceDialogTitle: 'Públicos',
    addAudienceGroupText: 'Adicionar grupo do público',
    allFieldsRequiredErrorMessage: 'Você deve inserir todos os campos obrigatórios para continuar.',
    diplicateAudiencesErrorMessage:
      'Públicos duplicados não são permitidos. Por favor, remova as duplicadas.',
    audienceSavedSuccessfullyMessage: 'Público salvo com sucesso',
    audienceNoBannerErrorMessage: 'Deve haver pelo menos 1 banner exibido para todos os PDVs'
  },
  miNegocioContentList: {
    pageTitle: 'Lista de conteúdo Mi Negocio',
    contentTabText: 'Conteúdo',
    metadaTabText: 'Metadados',
    newLaunchButtonText: 'Novo lançamento',
    longTipText: 'Dica longa',
    multipleEventsText: 'Múltiplo Eventos',
    eventText: 'Evento',
    podcastText: 'Podcast',
    videoText: 'Vídeo',
    dailyFeedText: 'Feed diário',
    challengeText: 'Desafio',
    authorText: 'Autor',
    categoryText: 'Categoria',
    tagText: 'Tag',
    metadataLabelTableHeadText: 'Rótulo',
    metadataLabelTableHeadTooltip: 'Rótulo de metadados',
    metadataTypeTableHeadText: 'Tipo de metadados',
    metadataCreatedOnTableHeadText: 'Criado em',
    metadataCreatedOnTableHeadTooltip: 'Criado em, data e hora',
    metadaStatusTableHeadTooltip: 'Status dos metadados',
    metadaCreateDataButtonText: 'Criar dados',
    titleTableHeadText: 'Título',
    contentTypeTableHeadText: 'Tipo do conteúdo',
    audienceTableHeadTooltip: 'Público do conteúdo',
    timezoneTableHeadTooltip: 'Fuso horário do conteúdo',
    statusTableHeadTooltip: 'Status do conteúdo'
  },
  homePage: {
    title: 'Olá {{name}}, bem-vindo!',
    subtitle:
      'Aqui você gerencia conteúdo para diferentes plataformas e também acessa painéis de campanha.',
    featuresTitle: 'Recursos',
    cards: {
      features: {
        title: 'Recursos',
        beesConsumerDescription:
          'Gerencie banners e visualizações da web na lista de inicialização do cliente BEES.',
        beesConsumerLinkText: 'Gerenciar conteúdo de clientes',
        beesPartnerDescription: 'Carregue e gerencie conteúdo para fachadas de lojas de parceiros.',
        beesPartnerLinkText: 'Gerenciar conteúdo de parceiros',
        beesMiNegocioDescription: 'Crie e gerencie todo o conteúdo para o Mi Negocio.',
        beesMiNegocioLinkText: 'Gerenciar conteúdo do Mi Negocio',
        beesDeepLinkGeneratorDescription: 'Crie deep links para serem usados nas comunicações.',
        beesDeepLinkGeneratorLinkText: 'Gerar deep links',
        beesDeepLinkMonitoringDescription:
          'Gerencie e monitore todos os dados gerados por deep links.',
        beesDeepLinkMonitoringLinkText: 'Ir para o painel'
      },
      dashboard: {
        title: 'Painéis'
      }
    }
  },
  partnerStorefront: {
    title: 'Olá {{name}}!',
    subtitle: 'Selecione uma loja para continuar.'
  },
  monitoringDashboard: {
    title: 'Monitoramento',
    subtitle:
      'Os itens desta página podem levar algum tempo para carregar. Todas as informações e gráficos são gerados pelo New Relic.',
    paragraph: 'Você pode descobrir como ler os gráficos neste '
  },
  deepLinkGenerator: {
    createDeepLinkText: 'Criar deep link',
    countryAndVendorTitleText: 'País e fornecedor',
    countryAndVendorSubtitleText:
      'Selecione para qual país e fornecedor você deseja criar o deep link.',
    countrySelectPlaceholder: 'Selecionar país',
    vendorSelectPlaceholder: 'Selecionar fornecedor',
    pathSelectorSubtitleText: 'Selecione onde deseja criar o deep link.',
    pathSelectorSearchInputPlaceholder: 'Pesquisar por nome do caminho',
    paramStoreSelectorSubtitle:
      'Selecione para qual loja você deseja criar o deep link e mostrar os parâmetros disponíveis. ',
    resultPageTitle: 'Deep link criado com sucesso',
    resultPageSubtitle: 'Seu deep link está ativo e pronto para ser usado.',
    needToCreateNewOneText: 'Precisa criar um novo?',
    leaveDeepLinkGeneratorModalTitle: 'Sair da criação de deep links?',
    leaveDeepLinkGeneratorModalContentText: 'Todas as informações serão perdidas.',
    leaveDeepLinkGeneratorModalPrimaryButtonText: 'Sim, sair',
    leaveDeepLinkGeneratorModalSecondaryButtonText: 'Não, voltar',
    leaveWithoutCopyingLinkModalTitle: 'Sair da criação de deep link sem copiar o link?',
    leaveWithoutCopyingLinkModalContentText: 'O deep link será perdido.'
  },
  shared: {
    brandCarouselTypeText: 'Banner de marca da página inicial para web',
    guestHeroCarouselTypeText: 'Convidado da página inicial para web',
    heroCarouselTypeText: 'Banner de herói da página inicial para web',
    mobileBannersTypeText: 'Banner de herói da página inicial para dispositivos móveis',
    streamlinedBannersTypeText: 'Banners simplificados para dispositivos móveis',
    webviewLinkTypeText: 'Link de visualização da web para dispositivos móveis',
    liveStatusText: 'ao vivo',
    pastStatusText: 'acabado',
    finishedStatusText: 'finalizado',
    scheduledStatusText: 'agendado',
    newStatusText: 'novo',
    activeStatusText: 'ativo',
    inactiveStatusText: 'inativo',
    noAudienceText: 'Sem público',
    beesCustomerSidebarText: 'Cliente BEES',
    beesPartnerStorefrontText: 'Fachada de loja do parceiro',
    beesMiNegocioText: 'Mi Negocio',
    beesDeepLinkGeneratorText: 'Gerador de deep link ',
    beesDeepLinkMonitoringText: 'Painel de monitoramento de deep link',
    audienceBuilderSidebarText: 'Construtor de Público',
    zoneConfigurationSidebarText: 'Configuração de ZONA',
    paginationCounterText: 'de',
    rowsPerPageText: 'Itens por página',
    editText: 'editar',
    optionalText: 'opcional',
    defaultErrorNotificationText: 'Algo deu errado! Tente novamente.',
    reviewContentText: 'Revise o conteúdo:',
    confirmText: 'Confirmar',
    searchText: 'Pesquisar',
    typeText: 'Tipo',
    launchDateText: 'Data de lançamento',
    cancelText: 'Cancelar',
    timezoneText: 'Fuso horário',
    deleteText: 'Excluir',
    countryText: 'País',
    vendorText: 'Fornecedor',
    nextText: 'Próximo',
    pathText: 'Caminho',
    createText: 'Criar',
    backText: 'Voltar',
    selectStoreText: 'Selecionar loja',
    copyText: 'Copiar',
    storeText: 'Loja',
    parametersText: 'Parâmetros',
    saveText: 'Salvar',
    countries: {
      aq: 'Antártica',
      ar: 'Argentina',
      be: 'Bélgica',
      br: 'Brasil',
      ca: 'Canadá',
      cl: 'Chile',
      co: 'Colômbia',
      de: 'Alemanha',
      do: 'República Dominicana',
      ec: 'Equador',
      es: 'Ilhas Canárias',
      gh: 'Gana',
      hn: 'Honduras',
      id: 'Indonésia',
      mx: 'México',
      nl: 'Holanda',
      pa: 'Panamá',
      pe: 'Peru',
      ph: 'Filipinas',
      py: 'Paraguai',
      sv: 'El Salvador',
      tz: 'Tanzânia',
      ug: 'Uganda',
      us: 'Estados Unidos',
      uy: 'Uruguai',
      gb: 'Reino Unido',
      za: 'África do Sul',
      kr: 'Coreia do Sul'
    },
    locales: {
      es: 'Espanhol',
      zh: 'Chinês',
      en: 'Inglês',
      fr: 'Francês',
      fil: 'Filipino',
      id: 'Bahasa',
      sw: 'Suaíli',
      ko: 'Coreano',
      pt: 'Português',
      de: 'Alemão',
      nl: 'Holandês'
    }
  }
}
