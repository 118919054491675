/* eslint-disable max-lines */
import { TranslationsObject } from '@Contexts/I18nContext/I18nContext.types'

export const koKR: TranslationsObject = {
  zoneSelectionPage: {
    modalTitle: '안녕 {{name}}, 환영!!',
    modalSubTitle: '계속하려면 국가를 선택하세요.'
  },
  contentListPage: {
    contentListPageTitle: '배너 실행 목록',
    customerPageTitle: 'BEES 고객 콘텐츠 목록',
    partnerstoreBreadcrumbText: 'BEES Partner storefront 콘텐츠 목록',
    addContentButtonText: '실행 생성',
    searchInputPlaceholder: '제목으로 찾기',
    filtersButtonText: '필터',
    filteredByText: '필터링 기준',
    statusTabText: '상태',
    editedByTabText: '편집자',
    editedByInputPlaceholder: '이메일 입력',
    startDateInputPlaceholder: '시작일',
    endDateInputPlaceholder: '종료일',
    filtersApplyButtonText: '적용',
    contentTypeActiveFilterText: '유형',
    statusActiveFilterText: '상태',
    editedByActiveFilterText: '편집자',
    startDateActiveFilterText: '시작일',
    startDateErrorText: '적어도 01/01/2019',
    endDateActiveFilterText: '종료일',
    endDateErrorText: '잘못된 날짜',
    contentTitleTableHeadText: '제목',
    contentTitleTableHeadTextMinified: '콘텐츠 제목',
    contentTitleTableHeadTooltipText: '실행 제목 및 유형.',
    actionsTableHeadText: '작업',
    audienceTableHeadText: '대상',
    launchTableHeadText: '실행 날짜',
    launchTableHeadTooltipText: '실행 날짜 및 시간.',
    timezoneTableHeadTooltipText: '시간대를 시작합니다.',
    statusTableHeadTooltipText: '실행 상태.',
    lastEditTableHeadText: '최종 편집',
    lastEditTableHeadTooltipText: '날짜, 시간, 이메일 작성자.',
    deleteLaunchButtonTooltipText: '실행 삭제.',
    deleteLaunchModalTitle: '이 실행을 삭제하시겠습니까?',
    deleteLaunchModalWarningText: '삭제하면 이 실행에 저장된 모든 데이터가 상실됩니다',
    deleteLaunchModalWarningTextMinified: '모든 콘텐츠 정보가 손실됩니다.',
    deleteLaunchModalCancelButtonTextMinified: '아니요, 돌아가세요',
    deleteLaunchModalSubmitButtonTextMinified: '예, 삭제합니다',
    deleteLaunchMessageNotificationText: '실행이 삭제되었습니다!',
    deleteLaunchNotFoundErrorNotificationText: '발사를 찾을 수 없습니다!',
    deleteLiveLaunchErrorNotificationText: '라이브 시작은 삭제할 수 없습니다!',
    noResultsWereFoundText: '검색된 결과물이 없습니다.',
    bannerLaunchTitle: '배너 출시',
    webViewLaunchTitle: '웹뷰 출시',
    tableItemActionShowDetailsText: '세부정보 표시'
  },
  contentEditorPage: {
    contentEditorPageTitle: '실행 편집',
    newLaunchPageTitle: '실행 생성',
    launchInformationText: '실행 속성',
    contentTitleLabel: '제목',
    contentTitlePlaceholder: '제목 추가',
    contentTitleErrorText: '제목을 생성하셔야 합니다',
    contentTypePlaceholder: '유형을 선택하세요',
    contentTypeErrorText: '유형을 선택하셔야 합니다',
    contentTimeZonePlaceholder: '시간대 선택',
    contentTimeZoneErrorText: '시간대를 선택해야 합니다',
    launchLabelPreviewFallbackText: '배너 선택',
    launchPlaceholder: '날짜 설정',
    launchErrorText: '유효한 날짜 또는 시간을 입력하십시오',
    timeLabel: '시작 시간',
    timePlaceholder: '시간 설정',
    timeErrorText: '유효한 날짜 또는 시간을 입력하십시오',
    bannersText: '배너 정보',
    bannerImagePlaceholder: '이미지를 끌어서 놓거나 업로드하십시오',
    bannerImageButtonText: '파일 업로드',
    bannerImageTipText:
      'JPG, PNG 또는 GIF 형식과 {{widthImgText}} x {{heightImgText}} 규격이 지원되며 {{maxSizeImgText}} 파일 크기가 권장됩니다.',
    webHomepageHeroBannerTipText:
      'JPG, PNG 또는 GIF 형식과 {{widthImgText}} x {{heightImgText}} 또는 {{widthImgText2}} x {{heightImgText2}} 규격이 지원되며 {{maxSizeImgText}}  파일 크기가 권장됩니다.',
    bannerImageErrorText: '계속하려면 이미지 배너가 있어야 합니다.',
    bannerLabel: '라벨',
    bannerLabelPlaceholder: '라벨 추가',
    bannerLabelErrorText: '계속하려면 배너 라벨이 있어야 합니다',
    alternativeTextLabel: '대체 문구',
    alternativeTextPlaceholder: '대체 문구 추가',
    linkLabel: '링크',
    linkPlaceholder: 'http / https / bees / tada',
    linkErrorText: '링크는 http://, https://, tada:// 또는 bees://로 시작해야 합니다',
    advancedConfigurationsText: '고급 설정',
    vendorAssociationLabel: '벤더 협회',
    vendorAssociationPlaceholder: '하나 이상의 벤더 ID 입력',
    vendorAssociationErrorText: '잘못된 공급업체를 선택했습니다',
    displayToUnassociatedVendorLabel: '협회 미가입 도매상 표시',
    addNewBannerText: '신규 배너 추가',
    submitButtonText: '제출하다',
    createNewLaunchSubmitButtonText: '실행 생성',
    updateSubmitButtonText: '실행 업데이트',
    updateLaunchSuccessNotificationText: '실행이 업데이트되었습니다!',
    createNewLaunchSuccessNotificationText: '실행이 생성되었습니다!',
    deleteBannerNotificationText: '배너가 삭제되었습니다!',
    pastDateTimeErrorNotificationText: '과거 날짜 또는 시간에는 출시를 생성할 수 없습니다.',
    updatePassedOrScheduleModuleTitle: '기존 출시를 업데이트하고 있습니다.',
    updatePassedOrScheduleModuleDescription: '업데이트 후 변경된 모든 정보가 교체됩니다.',
    mandatoryFieldsErrorNotificationText: '필수 필드를 작성하십시오.',
    overwriteLaunchModalTitle:
      '이 날짜와 시간에 출시가 이미 있습니다. 기존 출시 데이터를 덮어쓰시겠습니까?',
    overwriteLaunchModalTitlePartOne: '이 날짜와 시간에 출시가 이미 있습니다',
    overwriteLaunchModalTitlePartTwo: '기존 출시 데이터를 덮어쓰시겠습니까?',
    liveLaunchUpdateModalTitle: '실시간 실행을 업데이트하고 계십니다',
    liveLaunchUpdateModalText: '업데이트 후, 변경 사항이 실시간으로 실행됩니다.',
    liveLaunchUpdateModalSubmitButtonText: '실행 업데이트',
    copyLaunchModalTitle: '사본을 생성할 {{status}} 시작 날짜와 시간을 업데이트 중입니다.',
    copyLaunchModalText: '출시 날짜와 시간을 업데이트하면 자동으로 새로운 출시가 생성됩니다.',
    copyLaunchModalSubmitButtonText: '새 출시 만들기',
    lastEditText: '최종 편집',
    requiredField: '필수 필드.',
    specificationPageTextBegin: '우리를 방문하세요',
    specificationPageTextLink: '사양 페이지',
    specificationPageTextEnd: '콘텐츠 인코딩에 대한 모든 세부 사항을 확인하려면.',
    mediaTypeText: '매체 유형',
    imageText: '영상',
    videoText: '동영상',
    videoTipText: '동영상은 MP4 형식이어야 하며 크기가 20MB 미만이어야 합니다.',
    videoUploadButtonText: '동영상 업로드',
    videoAspectErrorMessage: '비디오의 가로 세로 비율은 16:9여야 합니다.',
    videoCallToActionCheckboxLabel: '행동 유도 버튼 추가',
    videoCallToActionLabel: '클릭 유도 문구 라벨',
    videoCallToActionPlaceholder: '이 버튼에 라벨을 추가하세요',
    videoAddThumbnailLabel: '썸네일 추가',
    videoAddThumbnailTooltip:
      '썸네일을 추가하지 않으면 동영상 재생 시간의 절반에 해당하는 정지 이미지가 대신 사용됩니다.',
    videoThumbnailTitle: '썸네일',
    videoThumbnailTipText:
      '썸네일은 16:9 비율, JPG, PNG 형식이어야 하며 크기는 1MB 미만이어야 합니다.',
    videoThumbnailUploadButtonText: '파일 찾아보기',
    videoThumbnailEnableMessage: '이 필드를 활성화하려면 비디오를 다시 업로드해야 합니다.',
    videoThumbnailAspectErrorMessage: '썸네일의 가로 세로 비율은 16:9여야 합니다.',
    webViewLinkTypeText: '웹뷰 링크',
    siteUrlLabel: '사이트 URL',
    siteUrlPlaceholder: '웹 보기 URL을 입력하세요.',
    gameLinkLabel: '게임 링크',
    gameLinkPlaceholder: 'CMS URL이 여기에 표시됩니다.',
    createGameBreadcrumbText: '게임 만들기',
    editGameBreadcrumbText: '게임 편집',
    editContentText: '콘텐츠 편집',
    copyLinkText: '링크 복사',
    fileIsTooBigErrorMessage: '파일이 너무 큽니다. 허용되는 최대 파일 크기는 2.00MB입니다.',
    titleMinimumCharactersErrorMessage: '제목은 4자 이상이어야 합니다.',
    urlErrorMessage: '유효한 URL을 삽입해야 합니다.',
    audienceSegmentationTitle: '분할',
    audienceSegmentationSubtitle: '이 배너를 볼 사람을 선택하세요.',
    audienceSegmentationSubtitleUS: '영향을 받을 사람을 정의합니다.',
    audienceSegmentationToggleLabel: '모든 POC에 배너 표시',
    audienceSegmentationToggleLabelUS: '제휴되지 않은 도매업자에게 표시',
    customAudiencesText: '맞춤 타겟',
    addCustomAudienceButtonText: '맞춤 잠재고객 추가',
    pocsTargetedText: '타겟 POC',
    audienceDialogTitle: '청중',
    addAudienceGroupText: '잠재고객 그룹 추가',
    allFieldsRequiredErrorMessage: '계속하려면 필수 필드를 모두 입력해야 합니다.',
    diplicateAudiencesErrorMessage: '중복된 관객은 허용되지 않습니다. 중복된 항목을 제거해 주세요.',
    audienceSavedSuccessfullyMessage: '잠재고객이 저장되었습니다.',
    audienceNoBannerErrorMessage: '모든 POC에는 배너가 1개 이상 표시되어야 합니다.'
  },
  miNegocioContentList: {
    pageTitle: 'Mi Negocio 콘텐츠 목록',
    contentTabText: '콘텐츠',
    metadaTabText: '메타데이터',
    newLaunchButtonText: '새로운 출시',
    longTipText: '긴 팁',
    multipleEventsText: '여러 이벤트',
    eventText: '이벤트',
    podcastText: '팟캐스트',
    videoText: '동영상',
    dailyFeedText: '일일 피드',
    challengeText: '도전',
    authorText: '작가',
    categoryText: '범주',
    tagText: '꼬리표',
    metadataLabelTableHeadText: '상표',
    metadataLabelTableHeadTooltip: '메타데이터 라벨',
    metadataTypeTableHeadText: '메타데이터 유형',
    metadataCreatedOnTableHeadText: '에 만든',
    metadataCreatedOnTableHeadTooltip: '생성 날짜, 시간',
    metadaStatusTableHeadTooltip: '메타데이터 상태',
    metadaCreateDataButtonText: '데이터 생성',
    titleTableHeadText: '제목',
    contentTypeTableHeadText: '컨텐츠 타입',
    audienceTableHeadTooltip: '콘텐츠 청중',
    timezoneTableHeadTooltip: '콘텐츠 시간대',
    statusTableHeadTooltip: '콘텐츠 상태'
  },
  homePage: {
    title: '안녕 {{name}}, 환영!',
    subtitle:
      '여기에서 다양한 플랫폼의 콘텐츠를 관리하고 캠페인 대시보드에 액세스할 수도 있습니다.',
    featuresTitle: '특징',
    cards: {
      features: {
        title: '특징',
        beesConsumerDescription: 'BEES Customer 실행 목록에서 배너 및 웹 보기를 관리합니다.',
        beesConsumerLinkText: '고객 콘텐츠 관리',
        beesPartnerDescription: '파트너 스토어프론트용 콘텐츠를 업로드하고 관리합니다.',
        beesPartnerLinkText: '파트너 콘텐츠 관리',
        beesMiNegocioDescription: 'Mi Negocio 의 모든 콘텐츠를 만들고 관리합니다.',
        beesMiNegocioLinkText: 'Mi Negocio 콘텐츠 관리',
        beesDeepLinkGeneratorDescription: '커뮤니케이션에 사용할 딥링크를 만듭니다.',
        beesDeepLinkGeneratorLinkText: '딥링크 생성',
        beesDeepLinkMonitoringDescription:
          '딥 링크를 통해 생성된 모든 데이터를 관리하고 모니터링합니다.',
        beesDeepLinkMonitoringLinkText: '대시보드로 이동'
      },
      dashboard: {
        title: '대시보드'
      }
    }
  },
  partnerStorefront: {
    title: '안녕 {{name}}!',
    subtitle: '계속하려면 매장을 선택하세요.'
  },
  monitoringDashboard: {
    title: '모니터링',
    subtitle:
      '이 페이지의 항목을 로드하는 데 다소 시간이 걸릴 수 있습니다. 모든 정보와 그래픽은 다음에 의해 생성됩니다 New Relic.',
    paragraph: '차트를 읽는 방법은 여기에서 확인할 수 있습니다 '
  },
  deepLinkGenerator: {
    createDeepLinkText: '딥링크 생성',
    countryAndVendorTitleText: '국가 및 공급업체',
    countryAndVendorSubtitleText: '딥링크를 생성하려는 국가와 공급업체를 선택하세요.',
    countrySelectPlaceholder: '국가 선택',
    vendorSelectPlaceholder: '공급업체 선택',
    pathSelectorSubtitleText: '딥링크를 생성할 위치를 선택하세요.',
    pathSelectorSearchInputPlaceholder: '경로 이름으로 검색',
    paramStoreSelectorSubtitle:
      '딥 링크를 생성하고 사용 가능한 매개변수를 표시하려는 매장을 선택하세요.',
    resultPageTitle: '딥링크가 생성되었습니다',
    resultPageSubtitle: '딥링크가 활성화되어 사용할 준비가 되었습니다.',
    needToCreateNewOneText: '새로 만들어야 하나요?',
    leaveDeepLinkGeneratorModalTitle: '딥 링크 생성을 종료하시겠습니까?',
    leaveDeepLinkGeneratorModalContentText: '모든 정보가 손실됩니다.',
    leaveDeepLinkGeneratorModalPrimaryButtonText: '응, 떠나',
    leaveDeepLinkGeneratorModalSecondaryButtonText: '아니요, 돌아가세요',
    leaveWithoutCopyingLinkModalTitle: '링크를 복사하지 않고 딥링크 생성을 종료하시겠습니까?',
    leaveWithoutCopyingLinkModalContentText: '딥링크가 손실됩니다.'
  },
  shared: {
    brandCarouselTypeText: '브랜드 캐러셀',
    guestHeroCarouselTypeText: '게스트 히어로 캐러셀',
    heroCarouselTypeText: '히어로 캐러셀',
    mobileBannersTypeText: '모바일 캐러셀',
    streamlinedBannersTypeText: '스트림라인 배너',
    webviewLinkTypeText: '웹뷰 링크',
    liveStatusText: '실시간',
    pastStatusText: '과거',
    finishedStatusText: '완료',
    scheduledStatusText: '예약됨',
    newStatusText: '새로운',
    activeStatusText: '활동적인',
    inactiveStatusText: '비활성',
    noAudienceText: '청중 없음',
    beesCustomerSidebarText: 'BEES 고객',
    beesPartnerStorefrontText: 'Partner storefront',
    beesMiNegocioText: 'Mi Negocio',
    beesDeepLinkGeneratorText: '딥링크 생성기',
    beesDeepLinkMonitoringText: '딥 링크 모니터링 대시보드',
    audienceBuilderSidebarText: '잠재고객 빌더',
    zoneConfigurationSidebarText: '구역 구성',
    paginationCounterText: '의',
    rowsPerPageText: '페이지당 항목수',
    editText: '편집하다',
    optionalText: '선택 사항',
    defaultErrorNotificationText: '문제가 발생했습니다! 다시 시도하십시오.',
    reviewContentText: '내용 검토:',
    confirmText: '확인',
    searchText: '찾다',
    typeText: '유형',
    launchDateText: '실행 날짜',
    cancelText: '취소',
    timezoneText: '시간대',
    deleteText: '삭제',
    countryText: '국가',
    vendorText: '공급업체',
    nextText: '다음',
    pathText: '길',
    createText: '만들다',
    backText: '뒤쪽에',
    selectStoreText: '매장 선택',
    copyText: '복사',
    storeText: '가게',
    parametersText: '매개변수',
    saveText: '구하다',
    countries: {
      aq: '남극 대륙',
      ar: '아르헨티나',
      be: '벨기에',
      br: '브라질',
      ca: '캐나다',
      cl: '칠레',
      co: '콜롬비아',
      de: '독일',
      do: '도미니카 공화국',
      ec: '에콰도르',
      es: '카나리아 제도',
      gh: '가나',
      hn: '온두라스',
      id: '인도네시아',
      mx: '멕시코',
      nl: '네덜란드',
      pa: '파나마',
      pe: '페루',
      ph: '필리핀',
      py: '파라과이',
      sv: '엘살바도르',
      tz: '탄자니아',
      ug: '우간다',
      us: '미국',
      uy: '우루과이',
      gb: '영국',
      za: '남아프리카',
      kr: '한국'
    },
    locales: {
      es: '스페인어',
      zh: '중국어',
      en: '영어',
      fr: '프랑스어',
      fil: '필리핀어',
      id: '바하사',
      sw: '스와힐리어',
      ko: '한국어',
      pt: '포르투갈어',
      de: '독일 사람',
      nl: '네덜란드 사람'
    }
  }
}
